export type AiResponse = {
    chatId?: string;
    answer?: string;
    chart?: string;
    data?: string[];
    query?: string;
    schema?: string[]
    question?: string;
}

export type ApiResponse = {
    chatId?: string;
    answer?: string;
    textResponse?: boolean;
    query?: string;
    table?: string[];
    schema?: string[];
    tableResponse: boolean;
    chart?: string;
    chartResponse: boolean;
    question?: string;
}

export type TextResponse = {
    answer: string
}

export type TableResponse = {
    table: string[];
    schema: string[]
}

export async function queryOpenAiHelper(question: string, cookie: any, chatId?: string) {
    const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : 'https://app-aa-back-pro-01.azurewebsites.net/api';
    const formData = new FormData();

    if (chatId) {
        formData.append("ChatId", chatId);
    }
    formData.append("Language", "es");
    formData.append("Question", question);
    return fetch(`${API_URL}/chat`, {
        method: "POST",
        body: formData,
        headers: {
            'accept': '*/*',
            'Authorization': 'Bearer ' + cookie
        }
    });
}

export async function extractResponse(promise: Promise<Response>, isvoicequestion: boolean): Promise<undefined | ApiResponse> {
    const response = await promise;
    let result: ApiResponse = {
        tableResponse: false,
        chartResponse: false
    }
    const json = await response.json()
    if (json.succeeded) {
        const api = json.result as AiResponse
        result.question = api.question;
        if (api.query) {
            result.answer = api.answer;
            result.table = api.data;
            result.schema = api.schema;
            result.query = api.query;
            result.tableResponse = true;
        }
        else if (api.answer) {
            result.answer = api.answer;
            result.textResponse = true;
        }
        else if (api.chart) {
            result.chart = api.chart;
            result.table = api.data;
            result.schema = api.schema;
            result.chartResponse = true;
        }
        result.chatId = api.chatId;
        return result
    }
}

