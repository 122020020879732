import React, { useEffect, useState, useRef } from 'react';


import {
    Group,
    Text,
    Card,
    Badge,
    Tabs
} from '@mantine/core';
import ResultTable from './ResultTable';
import { ChatMessage } from '../types';
import Markdown from 'react-markdown';

export default function Message({ data, position, length }: { data: ChatMessage, position: number, length: number }) {

    const [activeTab, setActiveTab] = useState("")
    const [codeToRun, setCodeToRun] = useState("")
    const [graphicRendered, setGraphicRendered] = useState(false);

    function getChartId(id) {
        return `chartid-${id}`
    }

    useEffect(() => {
        if (activeTab.length === 0) {
            if (data.textResponse || (data.tableResponse && (data.table === null || data.table.length === 0))) {
                setActiveTab("analysis")
            } else if (data.tableResponse) {
                setActiveTab("table")
            } else if (data.chartResponse) {
                changeBetweenTabs("graphic")
            }
        }
    }, [])

    useEffect(() => {
        if (codeToRun.length > 0 && !graphicRendered) {
            try {
                eval(codeToRun)
                setGraphicRendered(true)
            } catch (error) {
                console.log(error)
            }
        }
    }, [codeToRun, graphicRendered])

    function changeBetweenTabs(value: string) {
        setActiveTab(value)
        if (value === "graphic" && !graphicRendered) {
            const numeralSymbolOrNot = data.chartCode.includes("document.getElementById") ? "" : "#";
            let codeToEval = `

                const selector = '${numeralSymbolOrNot}${getChartId(position)}';
                const data = ${JSON.stringify(data.table)};

                ${data.chartCode.replace("```javascript", "").replace("```", "")}

                ;drawGraphic(data, selector);
            `
            setCodeToRun(codeToEval)
        }
    }

    function cleanFunction(value: string) {
        return value.replace("```javascript", "").replace("```", "")
    }

    return (
        <Card shadow="sm" padding="lg" radius="md" withBorder key={data.timestamp} style={{ marginBottom: position === length - 1 ? "8rem" : "1rem" }}>
            <Card.Section>
            </Card.Section>

            <Group justify={data.role === "usuario" ? "space-between" : "flex-end"} mt="md" mb="xs">
                <Badge color="pink">{data.role}</Badge>
            </Group>

            {data.role === "usuario" ? (
                <Text size="sm">
                    {data.message}
                </Text>
            ) : (
                data.textResponse ? (
                    <Text size="sm">
                        <Markdown>{data.message}</Markdown>
                    </Text>
                ) : (
                        <Tabs value={activeTab} onChange={(value) => { changeBetweenTabs(value) }}>
                            <Tabs.List>
                                {data.tableResponse && data.table && data.table.length > 0 &&
                                    <Tabs.Tab value="table">Tabla</Tabs.Tab>
                                }
                                {data.tableResponse &&
                                    <Tabs.Tab value="analysis">Análisis</Tabs.Tab>
                                }
                                {data.tableResponse &&
                                    <Tabs.Tab value="query">Consulta SQL</Tabs.Tab>
                                }
                                {data.chartResponse &&
                                    <Tabs.Tab value="graphic">Gráfico</Tabs.Tab>
                                }
                        </Tabs.List>
                        
                        <Tabs.Panel value="analysis">
                            <Text size="sm">
                                <Markdown>{data.message}</Markdown>
                            </Text>
                        </Tabs.Panel>
                        {data.table && data.table.length > 0 && (
                            <Tabs.Panel value="table">
                                <ResultTable table={data.table} schema={data.schema}></ResultTable>
                            </Tabs.Panel>
                            )
                        }
                        <Tabs.Panel value="query">
                            <Text size="sm">
                                <Markdown>{data.querydescription}</Markdown>
                            </Text>
                        </Tabs.Panel>
                        <Tabs.Panel value="graphic">
                            <canvas style={{ width: "100%", height: "400px" }} id={getChartId(position)}></canvas>
                        </Tabs.Panel>
                    </Tabs>
                )
            )}

        </Card>
    )
}
