import { useMsal } from "@azure/msal-react";
import Cookies from "js-cookie";
import { loginRequest } from "../authConfig";

export function useGetCookie() {
    const { instance } = useMsal();

    const getCookie = async () => {
        const cookie = Cookies.get('auth');
        if (!cookie) {
            const result = await instance.loginPopup(loginRequest);
            const token = result.accessToken;
            setCookie(token);
            instance.setActiveAccount(result.account);
            return { token };
        }
        return { token: cookie };
    };
    return getCookie;
}

export function setCookie(accessToken) {
    Cookies.set('auth', accessToken, { expires: 1/25 });
}
