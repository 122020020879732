import {
    ActionIcon,
    Box,
    Group,
    rem,
    Textarea,
    Text,
    Divider
} from '@mantine/core';

// Msal imports
import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from "../authConfig";

import { AppShell, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';

import { useState } from "react";
import { extractResponse, queryOpenAiHelper } from '../lib/ia';
import Message from '../ui-components/Message';
import { ChatMessage } from '../types';
import { buildNewMessage, setRichDataMessage } from '../lib/chat';
import { Loading } from "../ui-components/Loading";
import { ErrorComponent } from "../ui-components/ErrorComponent";
import { IconAdjustments, IconMicrophone, IconSearch, IconChartPie, IconSquareChevronRight, IconBrain } from '@tabler/icons-react';
import { activateMicrophone, queryOpenAiWithAudio } from "../lib/audio2";
import "https://cdn.jsdelivr.net/npm/chart.js@4.4.3/dist/chart.umd.min.js";
import { nanoid } from "nanoid";
import { ThreeDots } from 'react-loader-spinner'
import { useMediaQuery } from 'react-responsive'
import '../styles/styles.css'
import { useGetCookie } from '../lib/auth';
import { SampleQuestions } from '../ui-components/SampleQuestions';

/*
const IdToken = () => {
    const { accounts } = useMsal();
    const account = useAccount(accounts[0] || {});
    const [idToken, setIdToken] = useState("");

    useEffect(() => {
        if (account && account.idToken) {
            setIdToken(account.idToken);
        } else {
            setIdToken("");
        }
    }, [account]);
    
    if (idToken) {
        return idToken;
    } else {
        return null;
    }
};
*/


const UserChatContent = () => {
    const [opened, { toggle }] = useDisclosure();

    const [chatId, setChatId] = useState(undefined);
    const [userQuestion, setUserQuestion] = useState("");
    const [conversation, setConversation] = useState<Array<ChatMessage>>([

    ])
    const [isGeneratingResponse, setIsGeneratingResponse] = useState(false);
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1024px)' });
    const getCookie = useGetCookie();

    function queryHelperWithParam(question: string) {
        const messageCopy = `${question}`.replaceAll("\n", "")
        setConversation(oldValue => {
            const newObj = { message: messageCopy, role: "usuario", timestamp: Date.now(), chatId: chatId, nanoid: nanoid() } as ChatMessage
            return [...oldValue, newObj]
        })
        setUserQuestion("")
        queryOpenAi(messageCopy, chatId)
    }

    function queryHelper() {
        if (isGeneratingResponse || userQuestion.trim().length === 0) {
            return;
        }
        queryHelperWithParam(userQuestion);
    }

    function generateChart() {
        if (isGeneratingResponse) {
            return;
        }
        queryOpenAi("chart", chatId)
    }

    function performAnalysis() {
        if (isGeneratingResponse) {
            return;
        }
        queryOpenAi("analysis", chatId)
    }

    function onEnterKey(event: any) {
        if (event.key === "Enter") {
            queryHelper();
        }
    }

    async function queryOpenAi(question: string, chatId?: string) {
        setIsGeneratingResponse(true);
        scrollToLoader();
        const result = await getCookie();
        const response = await extractResponse(queryOpenAiHelper(question, result.token, chatId), false);

        setChatId(response.chatId);
        let newMessage = buildNewMessage() as ChatMessage
        setRichDataMessage(response, newMessage)
        setConversation(oldValue => {
            return [...oldValue, newMessage];
        });
        setIsGeneratingResponse(false);
    }

    async function captureMicrophone() {
        try {
            setIsGeneratingResponse(true);
            const blob = await activateMicrophone() as Blob;
            const result = await getCookie();
            const response = await extractResponse(queryOpenAiWithAudio(blob, result.token), true);

            const messageCopy = `${response.question}`
            setConversation(oldValue => {
                const newObj = { message: messageCopy, role: "usuario", timestamp: Date.now(), chatId: response.chatId, nanoid: nanoid() } as ChatMessage
                return [...oldValue, newObj]
            })

            setChatId(response.chatId);
            let newMessage = buildNewMessage()
            setRichDataMessage(response, newMessage)
            setConversation(oldValue => {
                return [...oldValue, newMessage];
            });
            setIsGeneratingResponse(false);
        } catch (error) {
            console.error('Error in capturaMicrophone:', error);
        } finally {
            setIsGeneratingResponse(false);
        }
    }

    return (
        <>

            <AppShell style={{ display: "flex", width: "100%", justifyContent: "center" }}>

                <AppShell.Main style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", width: "80%", minHeight: "0px" }}>
                
                    <Box style={{ width: "100%" }}>

                        {conversation.length === 0 ? (
                            <div style={{ display: "flex", placeContent: "center" }}>
                                <h2>Bienvenido al asistente analítico de JGB, ¿cómo puedo ayudarle?</h2>
                                <SampleQuestions onSelectQuestion={(e) => {queryHelperWithParam(e)}}></SampleQuestions>
                            </div>
                        ) : (
                            <div>
                                {conversation.map((data, i) => {
                                    return (
                                        <Message key={data.nanoid} data={data}
                                            position={i} length={conversation.length} />
                                    )
                                })}
                                
                                
                            </div>
                            )}
                            {isGeneratingResponse ? (
                                <ThreeDots
                                    visible={isGeneratingResponse}
                                    height="80"
                                    width="80"
                                    color="var(--mantine-color-pink-filled)"
                                    radius="9"
                                    ariaLabel="three-dots-loading"
                                    wrapperStyle={{position: 'absolute' as 'absolute',
                                        width: '80vw',
                                        display: 'flex' as 'flex',
                                        alignItems: 'center' as 'center',
                                        justifyContent: 'center' as 'center'}}
                                    wrapperClass="three-dots"
                                />
                            ):(null)}
                    </Box>
                    <Box display="flex" style={{
                        flexDirection: "column", marginTop: "20px", marginBottom: "20px",
                        position: "fixed", bottom: "20px", width: "60%", marginLeft: "auto",
                        marginRight: "auto", left: 0, right: 0
                    }}>

                        <Group style={{ marginBottom: 10, placeContent: "center", display: "flex", flexWrap: "nowrap" }}>

                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <ActionIcon variant="filled" color='grey' size={36}
                                    style={{ marginBottom: "3px" }}
                                    disabled={isGeneratingResponse}
                                    onClick={() => { performAnalysis() }}
                                >
                                    <IconBrain stroke={1.5} />
                                </ActionIcon>
                                <ActionIcon variant="filled" color='grey' size={36}
                                    disabled={isGeneratingResponse}
                                    onClick={() => { generateChart() }}
                                >
                                    <IconChartPie stroke={1.5} />
                                </ActionIcon>
                            </div>
                            <Textarea
                                disabled={isGeneratingResponse}
                                radius="md"
                                size="lg"
                                style={{ width: "100%" }}
                                placeholder="Escriba su consulta"
                                onKeyUp={(e) => { onEnterKey(e) }}
                                value={userQuestion}
                                onChange={(e) => { setUserQuestion(e.target.value) }}
                            />
                            <div style={{ display: "flex", flexDirection: "column" }}>
                                <ActionIcon variant="filled" size={36}
                                    style={{ marginBottom: "3px" }}
                                    disabled={isGeneratingResponse}
                                    onClick={() => { queryHelper() }}
                                >
                                    <IconSearch
                                        stroke={1.5} />
                                </ActionIcon>

                                <ActionIcon onClick={() => { captureMicrophone() }} disabled={isGeneratingResponse}
                                    size={36} variant="default" aria-label="ActionIcon with size as a number">
                                    <IconMicrophone stroke={1.5} />
                                </ActionIcon>
                            </div>

                            </Group>
                        
                    </Box>                       

                </AppShell.Main>
            </AppShell>
        </>
    );
};

function scrollToLoader() {
    setTimeout(() => {
        const classElement = document.getElementsByClassName('three-dots');
        if (classElement.length > 0) {
            classElement[0].scrollIntoView({ behavior: "smooth" });
        }
    }, 200);
}

export function UserChat() {
    const authRequest = {
        ...loginRequest
    };

    return (
        <>
            <UserChatContent /> 
            <MsalAuthenticationTemplate 
                interactionType={InteractionType.Redirect} 
                authenticationRequest={authRequest} 
                errorComponent={ErrorComponent} 
                loadingComponent={Loading}
            >
            </MsalAuthenticationTemplate>
        </>
      )
};
