import React from "react";
import {
    ActionIcon,
    Box,
    Group,
    rem,
    Textarea,
    Text,
    Divider
} from '@mantine/core';
import { Modal, Button } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { IconHelp } from '@tabler/icons-react';

const questions = [
    "Lorem ipsum dolor sit amet, consectetur",
    "Lorem ipsum dolor sit amet, consecdsatetur",
    "Lorem ipsum dolor sit amet, consectedsadasatur"
]

export function SampleQuestions({ onSelectQuestion }) {

    const styleObject = { cursor: "pointer" };
    const [opened, { open, close }] = useDisclosure(false);

    return (
        <div style={{ margin: "5px" }} >
            <Modal opened={opened} onClose={close} title="Preguntas de ejemplo">
                {questions.map(x => {
                    return (
                        <div key={x}>
                            <Text onClick={() => { onSelectQuestion(x) }} className="sample-question" size="sm" style={styleObject} >
                                {x}
                            </Text>
                            <Divider my="md" />
                        </div>
                    )
                })
                }
            </Modal>
            <ActionIcon onClick={open} variant="default" size="lg" aria-label="Gallery">
                <IconHelp style={{ width: rem(20) }} stroke={1.5} />
            </ActionIcon>
           
        </div>
    )
}
