import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./styles/theme";
import App from "./App";
import { loginRequest, graphConfig } from "./authConfig";

// MSAL imports
import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { setCookie } from "./lib/auth";

export const msalInstance = new PublicClientApplication(msalConfig);

msalInstance.initialize().then(async () => {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    const accounts = msalInstance.getAllAccounts();
    let authResponse;

    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);

        authResponse = await msalInstance.acquireTokenSilent({
            scopes: loginRequest.scopes,
            account: accounts[0]
        });
        setCookie(authResponse.accessToken);
    }

    msalInstance.addEventCallback(async (event: EventMessage) => {
        if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
            const payload = event.payload as AuthenticationResult;
            const account = payload.account;
            msalInstance.setActiveAccount(account);
            authResponse = await msalInstance.acquireTokenSilent({
                scopes: loginRequest.scopes,
                account: account
            });
            setCookie(authResponse.accessToken);
        }
    });
    
    const root = ReactDOM.createRoot(
        document.getElementById("root") as HTMLElement
    );
    root.render(
        <Router>
            <ThemeProvider theme={theme}>
                <App pca={msalInstance} />
            </ThemeProvider>
        </Router>
    );
});
