import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import WelcomeName from "./WelcomeName";
import SignInSignOutButton from "./SignInSignOutButton";
import { ReactComponent as Logo } from '../images/logo.svg';
import { Link as RouterLink } from "react-router-dom";
import { Margin } from "@mui/icons-material";

const NavBar = () => {
    return (
        <div style={{ flexGrow: 1, margin: '0px 0px 50px 0px' }}>
            <AppBar position="static" color="primary">
                <Toolbar>
                    <Typography style={{ flexGrow: 1 }}>
                        <Link
                            component={RouterLink}
                            to="/"
                            color="inherit"
                            variant="h6"
                        >
                            <Logo width={100} height={100} />
                        </Link>
                    </Typography>
                    <WelcomeName />
                    <SignInSignOutButton />
                </Toolbar>
            </AppBar>
        </div>
    );
};

export default NavBar;