import { encodeSync }  from "./wav-encoder"
import { nanoid } from "nanoid";

export async function activateMicrophone() {
    let audioContext = new (window.AudioContext)
    let audioData = [];
    try {
        let mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        let microphone = audioContext.createMediaStreamSource(mediaStream);
        let recorder = audioContext.createScriptProcessor(4096, 1, 1);

        recorder.onaudioprocess = function (e) {
            if (!audioData) return;
            const input = e.inputBuffer.getChannelData(0);
            audioData.push(new Float32Array(input));
        };

        microphone.connect(recorder);
        recorder.connect(audioContext.destination);

        const stopRecordingWithTimeout = async (timeout) => {
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    try {
                        mediaStream.getTracks().forEach(track => track.stop());
                        recorder.disconnect();
                        microphone.disconnect();

                        const buffer = mergeBuffers(audioData);
                        resolve(encodeWAV(buffer, audioContext.sampleRate));
                    } catch (error) {
                        reject(error);
                    }
                }, timeout);
            });
        };

        const wavData = await stopRecordingWithTimeout(8000);
        return wavData;
    } catch (err) {
        console.error('Error accessing microphone', err);
    }
};

export async function queryOpenAiWithAudio(blob: Blob, cookie: any) {
    const API_URL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_API_URL : 'https://app-aa-back-pro-01.azurewebsites.net/api';
    const formData = new FormData();
    formData.append("Language", "es");
    formData.append('VoiceQuestion', blob, `${nanoid()}.wav`);
    formData.append("Question", "");
    return fetch(`${API_URL}/chat`, {
        method: "POST",
        body: formData,
        headers: {
            'accept': '*/*',
            'Authorization': 'Bearer ' + cookie
        }
    });
}

function mergeBuffers(audioData) {
    const length = audioData.reduce((total, chunk) => total + chunk.length, 0);
    const result = new Float32Array(length);
    let offset = 0;
    for (const chunk of audioData) {
        result.set(chunk, offset);
        offset += chunk.length;
    }
    return result;
}

async function encodeWAV(buffer, sampleRate) {
    const wavData = encodeSync({
        sampleRate: sampleRate,
        channelData: [buffer]
    });
    return new Blob([wavData], { type: 'audio/wav' });
}
