import { nanoid } from "nanoid";

export function buildNewMessage() {
    const newId = parseInt(new String(Math.random()).replace(".", ""))
    let newMessage = { role: "asistente", timestamp: Date.now(), id: newId, nanoid: nanoid() }
    return newMessage
}

function removeDecimals(input: string) {
    return input.replaceAll(/(\d{1,3}(,\d{3})*)\.\d{2}/g, '$1');
}

export function setRichDataMessage(response, newMessage) {
    if (response.answer) {
        response.answer = removeDecimals(response.answer)
    }
    if (response?.textResponse) {
        newMessage.textResponse = true
        newMessage.message = response.answer
    }
    if (response?.tableResponse) {
        //let querysplit = response.query.split("\n\n");
        //let queryexplanation = querysplit[0].split("```postgresql\n");
        newMessage.tableResponse = true;
        newMessage.message = response.answer
        newMessage.table = response.table;
        newMessage.schema = response.schema;
        newMessage.querydescription = response.query;//"```postgresql\n" + queryexplanation[1];
    }
    if (response?.chartResponse) {
        newMessage.chartResponse = true
        newMessage.chartCode = response.chart
        newMessage.table = response.table
    }
    if (response?.chatId) {
        newMessage.chatId = response.chatId;
    }
}
