import { useState } from 'react';
import DataTable from 'react-data-table-component';

const BAD_WORDS_LIST = {
    "ano": "Año"
}

const FORMAT_COLUMNS = ["Defecto", "Pesos", "Fecha", "Separador"]
const COLUMNS_TO_IGNORE = ["Año", "Periodo"]

export default function ResultTable({ table, schema }: { table: string[], schema: string[] }) {

    const [dataTable, setDataTable] = useState([]);
    const [dataTableCopy, setDataTableCopy] = useState([]);
    const [fixedSchema, setFixedSchema] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);

    if (!table || (table && table.length === 0)) {
        return (
            <div className="p-2">
                <div className="h-4" />
            </div>
        )
    } else if (dataTable.length > 0 && fixedSchema.length > 0 && tableColumns.length > 0) {
        
        return (
            <div className="p-2">
                
                <DataTable
                    columns={tableColumns}
                    data={dataTable}
                    pagination
                />
                <div className="h-4" />
            </div>
        )
    } else if (table && schema) {

        const tempFixedSchema = fixBadWords(schema)
        const data = rebuildTableFromData(table, tempFixedSchema)
        const columns = fixedSchema.map(x => ({ name: x, selector: (row) => row[x] }))

        const numericColumnsList = numericColumns(data).reduce((acc, key) => {
            acc[key] = true;
            return acc;
          }, {});

        setDataTable(data.map(x => {
            let temp = { ...x }
            for (const property in x) {
                if (numericColumnsList[property] && COLUMNS_TO_IGNORE.findIndex(x => x === property) === -1) {
                    temp[property] = formatNumber(temp[property]);
                }
            }
            return temp;
        }))
        setDataTableCopy(data)
        setFixedSchema(tempFixedSchema)
        setTableColumns(columns)

        return (
            <div className="p-2">
                <div className="h-4" />
            </div>
        )
    }
    
    function rebuildTableFromData(table: string[], schema: string[]) {
        let objResult = []
        for (let row = 0; row < table.length; row += 1) {
            let objTemp = {}
            let index = 0
            for (const column of schema) {
                objTemp[column] = table[row][index]
                index += 1
            }
            objResult.push(objTemp)
        }
        return objResult
    }

    function fixBadWords(columns: string[]) {
        return columns.map(x => {
            if (BAD_WORDS_LIST[x]) {
                return BAD_WORDS_LIST[x]
            } else {
                return capitalizeFirstLetter(x)
            }
        })
    }

    function capitalizeFirstLetter(value: string) {
        return value.charAt(0).toUpperCase() + value.slice(1);
    }

    function numericColumns(rows: any): Array<string> {  
        if (rows.length === 0) {  
            return [];  
        }  
          
        const result = new Set<string>();  
        const length = rows.length;  
      
        for (let i = 0; i < length; i += 1) { // Cambiar el paso a 1 para revisar todos los registros  
            const fields = Object.keys(rows[i]);  
            for (let field of fields) {  
                const data = rows[i][field];  
                // Verifica si el dato es un número (entero o decimal)  
                const numberStr = data.replace(/\B(?=(\d{3})+(?!\d))/g, ".");   
                const isNumber = !Number.isNaN(parseFloat(numberStr));  
                if (isNumber) {  
                    result.add(field);  
                }  
            }  
        }  
      
        return Array.from(result.values());  
    }  

    function formatNumber(input) {  
        // Verifica si el input es un número en formato string  
        if (typeof input === 'string' && /^[0-9]+(?:\.[0-9]{1,2})?$/.test(input.replace(/,/g, '.'))) { 
            // Reemplazar el punto decimal por una coma  
            input = input.replace(/\.([0-9]+)$/, ',$1');  
            return input.replace(/\B(?=(\d{3})+(?!\d))/g, ".");   
        } else {  
            return input;  
        }  
    }
}
